import React, { useEffect, useMemo, useState } from "react";
import { CssSelect, CssAutoComplete } from "../StyledComponent";
import { useField, useFormikContext, ErrorMessage } from "formik";
import { Country, State, City } from "country-state-city";
import { CloseIcon } from "../../icons";
import { Box, MenuItem, ListSubheader, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { createFilterOptions } from "@mui/material/Autocomplete";
import _ from "lodash";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Paper from "@mui/material/Paper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function PreferredPlaceDropDown(props) {
  const [field, meta, helpers] = useField(props.name);
  //console.log(meta);
  const [country, setCountry] = useState(field?.value?.[props.index]?.country || "");
  const [state, setState] = useState(field?.value?.[props.index]?.states || []);
  const [stateOptions, setStateOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { setFieldValue } = useFormikContext();
  /**
   * Use effect is added since the country is not getting updated
   */
  //console.log(state);
  useEffect(() => {
    setCountry(field?.value?.[props.index]?.country);
    setState(field?.value?.[props.index]?.states);
  }, [field?.value, props.index, meta]);

  const selectInput = props.reportFilter
    ? "flex bg-white border min-h-formInputHeight rounded"
    : "flex bg-white border border-formInputBorder min-h-formInputHeight hover:border-black hover:border-2 hover:-mt-[1px] rounded-5px";
  const countrySelect = props.reportFilter
    ? "w-1/3 border-r-2 border-formInputBorder"
    : "w-1/5 border-r-2 border-formInputBorder";
  const stateSelect = props.reportFilter ? "w-2/3" : "w-4/5";
  //filter out the countries that is already been selected in other dropdowns
  let countryOptions = Country.getAllCountries().filter((data) => {
    return !field?.value?.some((countryStateObj, index) => {
      return countryStateObj.country === data.isoCode && index !== props.index;
    });
  });

  useEffect(() => {
    // setFieldValue(`preferredPlaceToRelocate.${props.index}.country`, "");
    let stateData = State.getAllStates();
    let filterData = stateData
      .filter((data) => {
        return data.countryCode === country;
      })
      .map((states) => {
        return states.name;
      });
    let cityData = City.getAllCities()
      .filter((x) => {
        return x.countryCode === country;
      })
      .map((city) => {
        return city.name;
      });
    let finalData = filterData.concat(cityData);
    //console.log(finalData);
    setStateOptions(_.uniq(finalData));
  }, [country]);

  useEffect(() => {
    if (props.reset === true) {
      setState([]);
      setSearchText("");
      setFieldValue(`${props.name}.${props.index}.states`, []);
    }
  }, [props.reset]);

  const handleChange = (e) => {
    const newCountry = e.target.value;

    //To Check if the selected country already exists in the selected countries
    const isCountrySelected = field?.value?.some((countryStateObj, index) => {
      if (index !== props.index) {
        return countryStateObj.country === newCountry;
      }
      return false;
    });

    if (!isCountrySelected) {
      setCountry(newCountry);
      setState([]);
      setFieldValue(`${props.name}.${props.index}.country`, newCountry);
      setFieldValue(`${props.name}.${props.index}.states`, []);

      let stateData = State.getAllStates();

      let filterData = stateData.filter((data) => {
        return data.countryCode === newCountry;
      });
      setStateOptions(filterData);
    }

    setSearchText("");
  };

  const handleStateChange = (e, value) => {
    let selValue = value;
    setState(typeof value === "string" ? selValue.split(",") : selValue);
    setFieldValue(`${props.name}.${props.index}.states`, value);
  };
  const containsText = (option, searchText) => {
    if (
      option.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      option.phonecode.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    ) {
      return option;
    }
  };

  const displayedOptions = useMemo(
    () => countryOptions.filter((option) => containsText(option, searchText)),
    [countryOptions, searchText]
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const sortedOptions = React.useMemo(() => {
    const selectedValues = field?.value?.[props.index]?.states.map((item) => item);
    const selectedOptions = stateOptions.filter((option) =>
      selectedValues.includes(option)
    );
    const remainingOptions = stateOptions.filter(
      (option) => !selectedValues.includes(option)
    );
    return [...selectedOptions, ...remainingOptions];
  }, [field?.value, props.index, stateOptions]);
  return (
    <div className="">
      <div className={selectInput}>
        <div className={countrySelect}>
          <CssSelect
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            onBlur={field?.onBlur}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={country}
            onChange={handleChange}
            className="w-full "
            disabled={props.disabled}
            name={`${props.name}.${props.index}.country`}
            style={{
              height: props.height ? props.height : "40px",

              borderRadius: "0px",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              borderRight: "0px",
            }}
            sx={{
              "&.MuiOutlinedInput-root": {
                height: props.height ? props.height : "40px",
                "& fieldset": {
                  border: "0px !important",
                },
                "&:hover fieldset": {
                  border: "0px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "0px",
                },
                "& .MuiOutlinedInput-input": {
                  //padding: "11.5px 14px",
                },
              },
            }}
            displayEmpty
            renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1 }}>
                  {value ? (
                    <img
                      className={props.reportFilter ? "w-6 h-4 mt-1" : "w-8 h-6"}
                      src={`https://flagcdn.com/w20/${value.toLowerCase()}.png`}
                      alt="flag"
                    ></img>
                  ) : null}
                </Box>
              );
            }}
            placeholder={
              meta.value?.[props.index]?.states?.length > 4 ? null : props.placeholder
            }
          >
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedOptions.map((data, index) => {
              return (
                <MenuItem key={index} value={data.isoCode} className="text-xs">
                  <div className="flex">
                    <img
                      className="w-8 h-6"
                      src={`https://flagcdn.com/w20/${data.isoCode.toLowerCase()}.png`}
                      alt="flag"
                    ></img>
                    <p className="text-xs font-xs text-labelColorSecondary mx-2 my-1">
                      {data.name}
                    </p>
                  </div>
                </MenuItem>
              );
            })}
          </CssSelect>
        </div>
        <div className={stateSelect}>
          {!props.reportFilter && (
            <CssAutoComplete
              multiple
              name={`${props.name}.${props.index}.states`}
              id="tags-outlined"
              options={stateOptions}
              getOptionLabel={(option) => option}
              getOptionDisabled={() =>
                meta.value?.[props.index]?.states?.length >= props.maxSelectedValue
              }
              disabled={props.disabled}
              value={meta?.value?.[props.index]?.states}
              filterOptions={filterOptions}
              filterSelectedOptions
              placeholder={
                meta.value?.[props.index]?.states?.length > 4 ? null : props.placeholder
              }
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& label.Mui-focused": {
                      color: "#2D2D2D",
                    },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "12px",
                      fontWeight: "500",
                      minHeight: "40px",
                      paddingY: "4px",
                      "& fieldset": {
                        border: "1px solid #70707059",
                        borderRadius: "10px",
                      },
                      "&:hover fieldset": {
                        border: "2px solid #2D2D2D",
                      },
                      "&.Mui-focused fieldset": {
                        border: "2px solid #2D2D2D",
                      },
                    },
                  }}
                  placeholder={
                    meta.value?.[props.index]?.states?.length > 4
                      ? null
                      : props.placeholder
                  }
                  {...params}
                  className={`${params.className} m-0 p-0`}
                />
              )}
              onChange={handleStateChange}
              ChipProps={{
                deleteIcon: <CloseIcon className="text-white h-[8px] w-[8px]" />,
                className: "bg-bgPrimary text-white h-6 text-xs  ml-1 mb-1",
              }}
              PaperComponent={({ children }) => (
                <Paper style={{ fontSize: "12px", width: "300px" }}>{children}</Paper>
              )}
              sx={{
                "&.MuiAutocomplete-root": {
                  "& fieldset": {
                    border: "0px !important",
                  },
                  "&:hover fieldset": {
                    border: "0px !important",
                  },
                  "&.Mui-focused fieldset": {
                    border: "2px solid #2D2D2D",
                  },
                },
              }}
            />
          )}
          {props.reportFilter && (
            <CssAutoComplete
              multiple
              name={`${props.name}.${props.index}.states`}
              id="tags-outlined"
              disableCloseOnSelect
              disableClearable
              options={sortedOptions}
              renderTags={() => null}
              value={state?.length > 0 ? meta.value[props.index].states : []}
              filterOptions={filterOptions}
              renderOption={(props, option, { selected }) => (
                <li {...props} className={`${props.className} m-0 p-0 `}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={state.length > 0 ? selected : null}
                  />
                  <div className="text-xs">{option}</div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="location-states"
                  variant="outlined"
                  placeholder={
                    state.length === 0
                      ? `Location`
                      : `Location (${meta.value[props.index].states.length})`
                  }
                 // style={{minHeight: "42px" ,backgroundColor:"green"}}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: props.height ? props.height : "50px",
                      "& fieldset": {
                        border: "1px solid #70707059",
                        borderRadius: "0px",
                      },
                      "&:hover fieldset": {
                        border: "2px solid #2D2D2D",
                      },
                      "&.Mui-focused fieldset": {
                        border: "2px solid #2D2D2D",
                      },
                      "& input": {
                        padding: "11px 14px",
                        boxSizing: "border-box",
                        fontSize: "12px",
                        color: "#9CA3AF",
                        fontWeight: "500",
                      },
                      "& .MuiInputLabel-root": {
                        transform: "translate(14px, 14px) scale(1)",
                      },
                    },
                  }}
                />
              )}
              onChange={handleStateChange}
              PaperComponent={({ children }) => (
                <Paper style={{ fontSize: "12px", width: "300px" }}>{children}</Paper>
              )}
              sx={{
                "&.MuiAutocomplete-root": {
                  // height: "42px !important",
                  "& fieldset": {
                    border: "0px !important",
                  },
                  "&:hover fieldset": {
                    border: "0px !important",
                  },
                  "&.Mui-focused fieldset": {
                    border: "2px solid #2D2D2D",
                  },
                },
              }}
            />
          )}
        </div>
      </div>
      <ErrorMessage
        name={`${props.name}.${props.index}.states`}
        component="div"
        className={`${
          props.quickResume ? "text-resumeTextColor" : " text-[#d32f2f]"
        } absolute text-[11px] mt-0.5`}
      ></ErrorMessage>
    </div>
  );
}

export default PreferredPlaceDropDown;
