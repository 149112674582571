import React, { useEffect, useState } from "react";
import menu from "../../routes/sidebar";
import { NavLink } from "react-router-dom";
import * as Icons from "../../icons";
import SidebarSubmenu from "./SidebarSubmenu";
import { Transition } from "react-transition-group";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material//NavigateBefore";
import { useTranslation } from "react-i18next";
import Zoom from "@mui/material/Zoom";
import { sidebarMenu } from "../../lib/onboarding-api";
import { useQuery } from "react-query";
import { CssTooltip } from "../StyledComponent";
import SidemenuSkltn from "../skeloton/SidemenuSkltn";
import newIcon from "../../assets/img/new.png";
const duration = 400;
const sidebarStyle = {
  transition: `width ${duration}ms`,
  boxShadow: "0px 6px 26px #22377B15",
};
const sidebarTransitionStyles = {
  entering: { width: "80px" },
  entered: { width: "250px" },
  exiting: { width: "250px" },
  exited: { width: "80px" },
};
const linkStyle = {
  transition: `opacity ${duration}ms`,
};
const menuTransistionStyle = {
  entering: { display: "none" },
  entered: { display: "block" },
  exiting: { display: "block" },
  exited: { display: "none" },
};

const footerTransistionStyle = {
  entering: { justifyContent: "end" },
  entered: { justifyContent: "end" },
  exiting: { justifyContent: "end" },
  exited: { justifyContent: "center" },
};

function Icon({ icon, ...props }) {
  const Icon = Icons[icon];
  if (Icon) return <Icon {...props} />;
}

function SidebarContent() {
  const [toggeled, setToggle] = useState(false);
  const [routes, setRoutes] = useState([]);

  const { t } = useTranslation();
  const {
    isLoading: isMenuLoading,
    error,
    isError,
  } = useQuery(["sidebarMenu"], sidebarMenu, {
    onSuccess: (resp) => {
      //console.log(resp);
      setRoutes(resp?.data?.data);
    },
  });
  return (
    <Transition in={toggeled} timeout={duration}>
      {(state) => (
        <div
          id="sidebarMain"
          className="relative h-full bg-bgPrimary flex-col border-r border-gray-100 hidden xl:block "
          style={{
            ...sidebarStyle,
            ...sidebarTransitionStyles[state],
            boxShadow: "1px 0px 10px #00000029",
          }}
        >
          <div className="absolute -right-4 top-3 border-gray-100  ">
            <div
              style={
                {
                  // ...sidebarStyle,
                  // ...sidebarTransitionStyles[state],
                }
              }
              onClick={() => setToggle(!toggeled)}
              className="cursor-pointer bg-white p-1 border-2 border-solid rounded-full "
            >
              {toggeled ? (
                <div className="flex justify-end items-center">
                  <NavigateBeforeIcon className="text-black" />
                </div>
              ) : (
                <div
                  className="flex justify-end items-center"
                  style={{ ...footerTransistionStyle[state] }}
                >
                  <NavigateNextIcon className="text-black" />
                </div>
              )}
            </div>
          </div>

          {!isMenuLoading ? (
            isError ? (
              <div className="flex flex-col justify-center h-full items-center text-white">
                {error?.response?.data?.message || error?.response?.statusText}
              </div>
            ) : (
              <div className="grow overflow-x-hidden flex flex-col h-full">
                <ul className="mt-14">
                  {/* {menu.map((route, i) => */}
                  {routes?.map((route, i) =>
                    route?.routes ? (
                      <SidebarSubmenu
                        route={route}
                        key={route.name}
                        style={{ ...menuTransistionStyle[state] }}
                        toggeled={toggeled}
                        setToggle={setToggle || true}
                      />
                    ) : true ? (
                      <li className={`relative text-white mb-3`} style={{height:"5vh"}} key={route.name} onClick={() => setToggle(false)}>
                        <NavLink
                          to={route?.path}
                          className={`inline-flex items-center w-full text-sm text-white transition-colors pl-7 duration-150 p-3 `}
                          style={({ isActive }) =>
                            isActive
                              ? { backgroundColor: "#E77817" }
                              : { backgroundColor: "transparent" }
                          }
                        >
                          <div className={`flex items-start gap-5`}>
                            <CssTooltip
                              title={!toggeled ? t(route.name) : ""}
                              placement="right"
                              TransitionComponent={Zoom}
                            >
                              <div className="">
                                <Icon
                                  className="w-5 h-4"
                                  aria-hidden="true"
                                  icon={route?.icon ? route.icon : ""}
                                />
                              </div>
                            </CssTooltip>

                            <div
                              style={{ ...menuTransistionStyle[state] }}
                              className={`text-xs ${
                                t(route.name) === "Refer Your Friends"
                                  ? "highlight-text"
                                  : ""
                              }`}
                            >
                              {t(route.name)}
                            </div>
                            {t(route.name) === "Refer Your Friends" && toggeled ? (
                              <img
                                src={newIcon}
                                alt="new"
                                className="h-9 w-9 absolute right-8 top-0.5"
                              />
                            ) : null}
                          </div>
                        </NavLink>
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            )
          ) : (
            <SidemenuSkltn state={state} />
          )}
        </div>
      )}
    </Transition>
  );
}

export default SidebarContent;
