import React, { useState, useRef, useEffect } from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { VariableSizeList as List } from "react-window";

const Dropdown = ({
  btnName,
  btnWidth,
  data,
  isLoading,
  reset,
  onApply,
  showButtons = true,
  filterType,
  isMulti = true,
  position,
  defaultSelectedData,
  jdData,
  width,
  inputType,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [finalOptions, setFinalOptions] = useState([]);
  const [divHeight, setDivHeight] = useState(150);
  const buttonRef = useRef(null);
  const listRef = useRef({}); // to get reference of list container
  const rowHeights = useRef({}); // to have heights of every row item
  const rootRef = useRef(null);

  const Styles = {
    btnPrimary: `bg-transparent text-gray-400 px-4 py-2 rounded border flex items-center h-[35px] ${
      btnWidth ? btnWidth : "min-w-[150px]"
    }`,
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = () => {
    //console.log(selectedOptions);
    setFinalOptions(selectedOptions);
    onApply(selectedOptions);
    handleClose();
  };

  const handleCancel = () => {
    // const data = finalOptions.filter((item) => selectedOptions.includes(item.value));
    // console.log("data", data);
    // console.log("finalOptions", finalOptions);
    setSelectedOptions(finalOptions);
    handleClose();
  };

  const handleCheckboxChange = (option, filterType) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (!isMulti) {
        return prevSelectedOptions.includes(option.value) ? [] : [option.value];
      } else {
        return prevSelectedOptions.includes(option.value)
          ? prevSelectedOptions.filter(
              (selectedOption) => selectedOption !== option.value
            )
          : [...prevSelectedOptions, option.value];
      }
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  //console.log("Data", data); // Add this line
  const filteredOptions =
    data?.length > 0
      ? data.filter((option) =>
          option.label?.toLowerCase().includes(searchText.toLowerCase())
        )
      : [];

  useEffect(() => {
    setSelectedOptions([]);
    setSearchText("");
    setFinalOptions([]);
  }, [reset]);

  useEffect(() => {
    if (defaultSelectedData) {
      setSelectedOptions(defaultSelectedData);
      setFinalOptions(defaultSelectedData);
    }
  }, [defaultSelectedData]);

  // Create a new array that combines selected options with remaining options
  const combinedOptions = [
    ...selectedOptions.map((selectedOption) => {
      return data.find((option) => option.value === selectedOption);
    }),
    ...filteredOptions.filter((option) => !selectedOptions.includes(option.value)),
  ];
  const Row = ({ index, style }) => {
    const rowRef = useRef({}); // to get the reference of every row item
    function setRowHeight(index, size) {
      listRef.current.resetAfterIndex(0);
      rowHeights.current = { ...rowHeights.current, [index]: size };
    }
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight); // get height for every item
      }
    }, [rowRef]);

    const currentOption = combinedOptions?.[index];
    // const selectedIndex = selectedOptions.indexOf(currentOption?.value);
    // const isLastSelectedOption =
    //   selectedIndex > -1 && selectedIndex === selectedOptions.length - 1;
    let pSkills;
    let sSkills;
    if (jdData) {
      pSkills = jdData[index]?.primary_skills?.map((pSkill) => {
        return pSkill.skill_name;
      });
      sSkills = jdData[index]?.secondary_skills?.map((sSkill) => {
        return sSkill.skill_name;
      });
    }
    return (
      <div style={style}>
        <div ref={rowRef} className="py-1">
          <label className="flex items-center gap-2 mb-2 text-xs break-words ">
            {inputType === "radio" ? (
              <input
                type={"radio"}
                name={combinedOptions?.[index]}
                checked={selectedOptions.includes(currentOption?.value)}
                onChange={() => handleCheckboxChange(currentOption, filterType)}
                onClick={() => {
                  if (selectedOptions.includes(currentOption?.value))
                    handleCheckboxChange(currentOption, filterType);
                }}
                className="min-w-[20px] w-4 h-4"
              />
            ) : (
              <input
                type={"checkbox"}
                name={combinedOptions?.[index]}
                checked={selectedOptions.includes(currentOption?.value)}
                onChange={() => handleCheckboxChange(currentOption, filterType)}
                className="min-w-[20px] w-4 h-4"
              />
            )}

            <div className="text-xs">{currentOption?.label}</div>
          </label>
          {jdData ? (
            <div className="ltr:ml-[28px] rtl:mr-[28px]">
              <div className="text-xs">
                <span className="font-semibold">Primary Skill - </span>
                {pSkills.join()}
              </div>
              <div className="text-xs">
                {" "}
                <span className="font-semibold">Secondary Skill - </span>
                {sSkills.join()}
              </div>
              <div className="text-xs">
                <span className="font-semibold">Experience - </span>
                {jdData[index]?.min_exp_years} years - {jdData[index]?.max_exp_years}{" "}
                years
              </div>
            </div>
          ) : null}
        </div>
        {/* {isLastSelectedOption && <hr className="w-full border my-2" />} */}
      </div>
    );
  };
  function getRowHeight(index) {
    return rowHeights.current[index] || 300;
  }

  useEffect(() => {
    if (rootRef.current) {
      setDivHeight(rootRef.current.clientHeight); // get the height of main container
    }
  }, []);
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={` ${btnWidth ? "" : "min-w-[150px]"}  relative`}>
        <button
          ref={buttonRef}
          onClick={handleOpen}
          className={open ? `${Styles.btnPrimary} border-gray-800` : Styles.btnPrimary}
        >
          <div className="flex justify-between w-full whitespace-nowrap">
            <div className="text-xs mt-1 font-medium">
              {t(btnName)}{" "}
              {finalOptions.length ? <span>({finalOptions.length})</span> : null}
            </div>
            <div className="flex items-center">
              {open ? <ExpandLess className="ml-2" /> : <ExpandMore className="ml-2" />}
            </div>
          </div>
        </button>
        {open ? (
          <div className="relative bottom-0 left-0 z-50">
            <div
              className={`bg-white shadow-md p-4 rounded  ${
                !position ? "absolute" : null
              } ${btnWidth ? "w-full" : "min-w-[150px]"}`}
            >
              <div className="mb-4">
                <div
                  className={`flex items-center border border-gray-300 px-2 py-1 rounded w-full `}
                >
                  <SearchIcon className="text-gray-400 mr-4 ml-1" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearchChange}
                    className="border-none outline-none w-full text-xs"
                  />
                </div>
              </div>
              {isLoading ? (
                <div className="flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <div ref={rootRef} className="max-h-64 overflow-y-auto">
                  <List
                    // height={150}
                    itemCount={combinedOptions?.length}
                    // itemSize={35}
                    ref={listRef}
                    height={divHeight}
                    itemSize={getRowHeight}
                    width={width ? width : 300}
                  >
                    {Row}
                  </List>
                  {/* {filteredOptions.map((option, index) => (
                    <div key={index} className="mb-4">
                      <label className="flex items-center mb-2 text-xs break-words">
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(option.value)}
                          onChange={() => handleCheckboxChange(option, filterType)}
                          className="mr-2 w-4 h-4"
                        />
                        {option.label}
                      </label>
                    </div>
                  ))} */}
                </div>
              )}
              {showButtons && (
                <div className="flex mt-2">
                  <button
                    onClick={handleApply}
                    className="bg-bgPrimary text-white px-4 py-2 rounded-full mr-2 relative w-24 text-xs"
                  >
                    {t("_APPLY")}
                  </button>
                  <button
                    onClick={handleCancel}
                    className="text-buttonPrimary px-4 py-2 rounded-full text-xs"
                  >
                    {t("_CANCEL")}
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default Dropdown;
