import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import OutlinedButton from "../Button/OutlinedButton";
import Button from "../Button/Button";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../icons";
export default function ConfirmDelete(props) {
  const {
    onCancelClick,
    open,
    onDeleteClick,
    message,
    maxWidth,
    isDataLoading,
    ...other
  } = props;
  const { t } = useTranslation();
  const handleCancel = () => {
    onCancelClick();
  };

  const handleOk = () => {
    onDeleteClick();
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "80%", maxWidth: maxWidth, maxHeight: 435 },
      }}
      maxWidth="xs"
      open={open}
      {...other}
      className="p-6"
    >
      <DialogTitle className="text-medium">
        <div className="flex flex-row">
          <span className="flex-grow text-sm">{t("_Delete")}</span>
          <CloseIcon
            className="w-3 h-3 text-right text-closeIcon mx-3"
            onClick={handleCancel}
          ></CloseIcon>
        </div>
      </DialogTitle>
      <DialogContent className={`text-xs pt-5 border-t border-dialogBorder`}>
        {message}
      </DialogContent>
      <DialogActions className="mr-5 mb-5">
        <OutlinedButton
          autoFocus
          onClick={handleCancel}
          label="_CANCEL"
          buttonClassName={"mr-4 text-sm"}
        ></OutlinedButton>
        <Button
          onClick={handleOk}
          label="_DELETE"
          disabled={isDataLoading}
          loading={isDataLoading}
          buttonClassName={"text-sm"}
        ></Button>
      </DialogActions>
    </Dialog>
  );
}
