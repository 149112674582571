import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { DropdownIcon } from "../../icons";
import { NavLink } from "react-router-dom";
import * as Icons from "../../icons";
import { CssTooltip } from "../StyledComponent";
import { useTranslation } from "react-i18next";
import Zoom from "@mui/material/Zoom";
function Icon({ icon, ...props }) {
  const Icon = Icons[icon];
  return <Icon {...props} />;
}

function SidebarSubmenu({ route, style, toggeled, setToggle }) {
  const [issubMenuOpen, setIsSubMenuOpen] = useState(false);
  const { t } = useTranslation();
  const [activeSubMenu, setActiveSubMenu] = useState(true);
  function handleDropdownMenuClick() {
    setIsSubMenuOpen(!issubMenuOpen);
    if (setToggle) setToggle(true);
  }

  return (
    <li className={`relative text-white mb-3`} key={route.name}>
      <div
        onClick={() => {
          handleDropdownMenuClick();
        }}
        className={`inline-flex items-center w-full text-sm text-white transition-colors pl-7 duration-150 p-3 cursor-pointer `}
        // style={({ isActive }) =>
        //   isActive ? { backgroundColor: "#E77817" } : { backgroundColor: "transparent" }
        // }
      >
        <div className={`flex items-start gap-5`}>
          <CssTooltip
            title={!toggeled ? t(route.name) : ""}
            placement="right"
            TransitionComponent={Zoom}
          >
            <div className="">
              <Icon
                className="w-5 h-4"
                aria-hidden="true"
                icon={route?.icon ? route.icon : ""}
              />
            </div>
          </CssTooltip>

          <div
            style={style}
            className="text-xs"
            //onClick={() => handleDropdownMenuClick()}
          >
            {t(route.name)}
          </div>
        </div>
      </div>
      {(issubMenuOpen && toggeled) || window.screen.width < 768 ? (
        // <ul
        //   //className="p-2 mt-2 space-y-2 overflow-hidden text-xs bg-transparent  text-white rounded-md pl-14 bg-gray-50"
        //   aria-label="submenu"
        // >
        <>
          {route.routes.map((r, i) => (
            // <li
            //   //className="px-2 py-1 transition-colors duration-150 dark:hover:text-gray-200"
            //   className={`inline-flex items-center w-full text-sm text-white transition-colors pl-7 duration-150 p-3 ${
            //     activeSubMenu ? "bg-red-600" : ""
            //   }`}
            //   key={r.name}
            // >
            <NavLink
              key={i}
              className="inline-flex items-center w-full text-xs pl-[74px] text-white transition-colors duration-150 p-2"
              to={r.path}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#E77817" }
                  : { backgroundColor: "transparent" }
              }
              onClick={() => {
                setActiveSubMenu(true);
                setToggle(!toggeled);
              }}
            >
              {t(r.name)}
            </NavLink>
            // </li>
          ))}
        </>
      ) : // </ul>
      null}
    </li>
    // <li className="relative px-6 py-3" key={route.name}>
    //   <button
    //     className="inline-flex items-center justify-between w-full text-xs  text-white  transition-colors duration-150 dark:hover:text-gray-200"
    //     onClick={handleDropdownMenuClick}
    //     aria-haspopup="true"
    //   >
    //     <span className="inline-flex items-center">
    //       <Icon className="w-5 h-4" aria-hidden="true" icon={route.icon} />
    //       <span className="ml-6">{t(route.name)}</span>
    //     </span>
    //     {/* <DropdownIcon className="w-4 h-4" aria-hidden="true" /> */}
    //   </button>
    //   {!isDropdownMenuOpen ? (
    //     <ul
    //       className="p-2 mt-2 space-y-2 overflow-hidden text-xs bg-transparent  text-white rounded-md ml-8 bg-gray-50"
    //       aria-label="submenu"
    //     >
    //       {route.routes.map((r) => (
    //         <li
    //           className="px-2 py-1 transition-colors duration-150 dark:hover:text-gray-200"
    //           key={r.name}
    //         >
    //           <Link className="w-full" to={r.path} style={style}>
    //             {t(r.name)}
    //           </Link>
    //         </li>
    //       ))}
    //     </ul>
    //   ) : null}
    // </li>
  );
}

export default SidebarSubmenu;
